export const socialsData = {
    github: 'https://github.com/Bigyama256',
    facebook: 'https://www.facebook.com/bigyama8600',
    linkedIn: 'https://www.linkedin.com/in/bigyama-khanal-bb4632258',
    instagram: 'https://www.instagram.com/bigyama_khanal/',
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/clevermustafaa',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/16470571/mustafa-hawari',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}