import resume from '../assets/pdf/mustafa-resume.pdf'
import image from '../assets/jpg/bigyama_dp.jpg'

export const headerData = {
    name: 'Bigyama Khanal',
    title: "QA Engineer",
    desciption:"A highly motivated and enthusiastic QA engineer with over 2+ years of experience in of ensuring software quality, meeting project requirements, and delivering results. Proficient in designing and executing test plans, cases, and scripts, both manually and through automation, with a strong understanding of the testing procedures. Possess excellent problem-solving skills, an eye for detail, and the ability to work efficiently as part of a team or individually.",
    image: image,
    resumePdf: resume
}
