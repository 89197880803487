export const experienceData = [
    {
        id: 1,
        company: 'Aqore Software Pvt Ltd.',
        jobtitle: 'QA Engineer',
        startYear: '2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'F1 soft International',
        jobtitle: 'QA Engineer',
        startYear: '2021',
        endYear: '2022'
    },
]