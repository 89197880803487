export const educationData = [
    {
        id: 1,
        institution: 'Kathmandu Model Collage',
        address: "Bagbazar, Kathmandu, Nepal",
        course: 'Higher Secondary Education (Faculty-Science)',
        startYear: '2015',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'Nepal Engineering Collage',
        course: 'Bachelor of Engineering in Computer Science',
        address: "Duwakot,BHaktapur, Nepal",
        startYear: '2017',
        endYear: '2022'
    },
]