export const aboutData = {
  title: "About Me",
  description1:
    "Greetings, tech buddies! I am Bigyama Khanal hardworking, quick learner, and a disciplined QA Sentinel with solid knowledge of software testing with processes involved in it. I've applied my knowledge and expertise to useful work in while working toward my QA qualification, standing tall at the forefront of code defense based in Kathmandu, Nepal.",
  description2: `I believe in transforming the basic elements of code into a top-notch standard of quality. 
                  In the test furnace, I build a shield against the non-stop flow of bugs
                  making sure our digital creations come out untouched. Join me on this magical journey
                  where accuracy and quality mix perfectly to create a software wonder.
    `,
  description3: `
        I also have experience in various software testing methodologies, including black box testing, functional testing and regression testing since I've worked closely with QA engineers, Business Analyst and Developers to ensure that software applications met the specified requirements and were free of defects.
    `,
  description4: `
        As a QA, I believe that effective communication and collaboration with cross-functional teams are crucial to achieving success in software development projects. I am comfortable working in agile methodology and using tools like JIRA. I have a passion for mobile app development and I am constantly learning and improving my skills. Thank you for visiting my website and please feel free to contact me for any inquiries.
    `,
  image: 2,
};
